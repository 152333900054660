.coinbase-button {
  padding: 10px 20px;
  background-color: #FF4B33;
  color: white;
  font-weight: bold;
  font-size: 12px;
  border: 2px solid #FF4B33;
  border-radius: 5px;
  cursor: pointer;
  width: 150px;
  margin-top: 10px;
}

.coinbase-button:hover {
  background-color: white;
  color: #FF4B33;
}

.hidden {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}

.visible {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 600px;
  position: relative;
}

.iframe {
  width: 100%;
  height: 500px;
  border: none;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  color: #333;
  transition: color 0.2s ease-in-out;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 5px;
}

.closeButton:hover {
  color: red;
  background: rgba(0, 0, 0, 0.1);
}
