.icon-container {
    display: flex;
    flex-direction: row; /* Arrange icons horizontally */
    justify-content: center; /* Center the icons */
    align-items: center; /* Vertically align icons */
    gap: 0.1rem; /* Space between icons */
    width: 100%;
    max-width: 200px;
  }
  
  .icon {
    font-size: 2rem; /* Adjust icon size */
    color: #001E2D;
    width: 40px; /* Set width */
    height: 40px; /* Set height */
    border-radius: 50%; /* Circular border */
    border: 3px solid #001E2D; /* Blue border */
    background-color: white; /* White background */
    z-index: 1;
  }