.item {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    border: 2px solid lightgray;
    border-radius: 8px;
    width: 100%;           
    font-size: 20px;       
  }
  
  .title {
    display: flex;
    justify-content: left;
    align-items: center;
    font-weight: bold;
    font-size: 1.1rem;
    border-bottom: 2px solid gray;
    padding-bottom: 5px;
  }
  
  .container {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 1em;
    grid-template-areas:
        'title title'
        'main aside'
        'bottom bottom';
    min-height: 100vh; /* Ensures minimum full viewport height */
  }
  
  .item-3 {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center the pagination vertically */
    /* align-items: center; */
    width: 100%;
    padding: 5px; /* Adjust this padding to control the indent */
  }
  
  .item-4 {
    display: flex; /* Use flexbox for layout */
    flex-direction: column; /* Stack items vertically */
    justify-content: flex-start; /* Align items to the top */
    align-items: stretch; /* Make items take full width */
    gap: 10px; /* Add spacing between the components */
    border: 2px solid lightgray; /* Keep the border consistent */
    border-radius: 8px;
    width: 100%;
    font-size: 20px;
  }
  
  
  .gridContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  