.data-room-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000; /* Ensure it stays on top of other elements */
    padding: 10px; /* Optional padding */
    box-sizing: border-box;
  }
  
  .data-room-content {
    display: grid; /* Use grid instead of flexbox */
    grid-template-columns: 1fr 2fr; /* 2 columns, 1/3 and 2/3 of the space */
    gap: 20px; /* Space between the columns */
    height: calc(100% - 60px); /* Adjust for heading height and padding */
    width: 100%; /* Make sure the container spans the full width */
  }
   /* Folder section occupies the first column */
.folder-section {
    overflow-y: auto; /* Allow scrolling if content overflows */
    padding: 10px;
    margin: 0; /* Ensure no margin is causing the indent */
    background-color: #f9f9f9; /* Optional: Add background to the folder section */
  }
  
  /* Each folder container inside the folder section */
  .folder-container {
    margin-bottom: 20px;
    overflow: hidden;
  }
  
  /* Folder heading with folder icon */
  .folder-heading {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  
  /* Folder list item styling */
  .file-item {
    cursor: pointer;
    padding: 10px;
    margin-bottom: 5px; /* Adjust spacing between items */
    display: flex;
    align-items: center;
    border-radius: 4px; /* Optional: round the corners */
  }
  
  .file-item:hover {
    background-color: #f0f0f0;
  }
  
  /* File list */
  .file-list {
    list-style: none;
    padding: 0;
  }
  
  /* Optional: Add some padding to the folder list */
  .file-list li {
    padding-left: 25px; 
    font-size: 12px;
    
  }
  
  /* Icon styling */
  .file-item svg, .folder-heading svg {
    width: 20px;
    height: 20px;
  }
  
 
.pdf-viewer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100vh; /* Ensure the viewer takes full height of the viewport */
    width: 100%;   /* Ensure full width */
  }

  .pdf-viewer p {
    position: absolute; /* Position the text on top of the iframe */
    z-index: 10; /* Make sure the text is above the iframe */
    color: #333; /* Adjust text color if needed */
    font-size: 20px; /* Adjust font size */
    font-weight: bold; /* Optional: Make text bold */
    text-align: center; /* Center-align the text */
  }
  
  iframe {
    width: 100%;  /* Fill the full width */
    height: 100%; /* Fill the full height */
    border: none; /* Remove the border */
  }
  