/* Tabs.css */

/* Tab container */
.tabsList {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
    margin-top: 60px;
  }
  
  /* Individual tab styling (like links) */
  .tab {
    padding: 10px 20px;
    cursor: pointer;
    background: none;  
    border: none;      
    color: #6c757d;  
    font-size: 16px;
    text-decoration: none; 
    transition: color 0.3s ease;
    margin-right: 20px;   
    font-size: 12px;  
    text-transform: uppercase; 
    

  }
  
  /* Hover effect to give a subtle interaction */
  .tab:hover {
    color: #495057;  
  }
  
  /* Active tab styling (underline when selected) */
  .activeTab {
    color: #333;  /* Dark color for active tab */
    font-weight: bold; /* Make the active tab text bold */
    border-bottom: 3px solid #333; /* Dark gray thick underline */
  }
  
  /* Tab panel content */
  .tabPanel {
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    min-width: 750px;
    height: calc(100vh - 200px);
    overflow-y: auto;
  }
  

/* Style the scrollbar */
.tabPanel::-webkit-scrollbar {
  width: 10px; 
}

/* Style the scrollbar track */
.tabPanel::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light color for the track (the background area of the scrollbar) */
  border-radius: 5px;  /* Optional: round the corners of the scrollbar track */

}

/* Style the scrollbar thumb (the draggable part) */
.tabPanel::-webkit-scrollbar-thumb {
  background: #001E2D; /* Color of the draggable part */
  border-radius: 5px;  /* Optional: round the corners of the thumb */
}


/* Style the scrollbar buttons (arrows) */
.tabPanel::-webkit-scrollbar-button {
  display: block;
  height: 12px;
  width: 12px;
  background-color: transparent; /* Make the background transparent */
}

/* Create a triangle for the "up" and "down" buttons */
.tabPanel::-webkit-scrollbar-button:vertical:decrement {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #333; /* Color for the "up" triangle */
}

.tabPanel::-webkit-scrollbar-button:vertical:increment {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #333; /* Color for the "down" triangle */
}

/* Optionally add hover effect */
.tabPanel::-webkit-scrollbar-button:hover {
  background-color: #ddd; /* Add hover effect */
}
