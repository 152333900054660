.checkout-button {
    padding: 10px 20px;
    background-color: #FF4B33;
    color: white;
    font-weight: bold;
    font-size: 12px;
    border: 2px solid #FF4B33;
    border-radius: 5px;
    cursor: pointer;
    width: 150px;
    margin-top: 10px;
  }
  
  .checkout-button:hover {
    background-color:white;
    color:  #FF4B33;
  }
  