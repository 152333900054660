/* Center the content horizontally */
.accreditation-container {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Horizontally center content */
    text-align: left; /* Center align text */
    padding: 30px;
  }
  
  /* Style for the heading */
  .accreditation-container h3 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px; /* Space between heading and text */
  }
  
  /* Style for the paragraph */
  .accreditation-container p {
    font-size: 18px;
    color: black;
    margin-bottom: 30px; /* Space between paragraph and next content */
  }
  
  