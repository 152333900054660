.btn-holder {
    width: 30px;
    height: 8px;
    border-radius: 18px;
    background-color: lightgray;
    display: flex;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
}

.btn-circle {
    background-color: #170640;
    transform: translateX(0px);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    transition: 300ms;
}

/* When 'active' class is applied to the btn-circle, change background and transform */
.btn-circle.active {
    background-color: #170640;
    transform: translateX(15px);
    transition: 300ms;
}

/* Hide checkbox */
.checkbox {
    display: none;
}
