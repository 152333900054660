/* Main container for the investment detail page */
.investment-detail-container {
  padding: 0px;
  max-width: 1000px;
  margin: 0 auto;
  display: block; 
  height: auto;
  overflow: hidden;
}

.investment-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Buy Box Section */
.buy-box {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-bottom: 20px; /* Adds space between buy box and the rest of the content */
  flex-shrink: 0; /* Prevent buy box from shrinking */
}

.buy-box h3 {
  font-size: 14px;
  margin-bottom: 10px;
}

.buy-box p {
  font-size: 14px;
}

.investment-progress {
  margin-top: 20px;
}

.investment-progress p {
  font-size: 16px;
}

button {
  padding: 10px 20px;
  background-color: rgba(116, 5, 5, 1);
  color: white;
  font-weight: bold;
  font-size: 12px;
  border: rgba(116, 5, 5, 1) 2px solid;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

button:hover {
  background-color: white;
  color: rgba(116, 5, 5, 1);
  border: rgba(116, 5, 5, 1) 2px solid;
}


.terms-header {
  font-weight: bold;
}

.terms-body {
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}

/* Investment Details Section */
.investment-details {
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.investment-details h2 {
  font-size: 28px;
  margin-bottom: 20px;
}

.investment-details p {
  font-size: 16px;
  line-height: 1.6;
}

.investment-image {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
  margin-bottom: 20px;
}

.divider {
  width: 100%;
  border-top: 1px solid #ddd;
  margin: 20px 0;
}