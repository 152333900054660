.contract-container {
  font-family: 'Times New Roman', Times, serif;
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  background-color: transparent;
  border-radius: 8px;
}

.contract-content {
  margin-top: 30px;
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 0px;
  margin-bottom: 20px;
}

.personal-info > div,
.employer-info > div,
.signature-line-container,
.date-line-container {
  position: relative;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  color: black;
}

.personal-info label,
.employer-info label,
.signature-label,
.date-label {
  font-size: 16px;
  margin-right: 10px;
  min-width: 150px;
  flex-shrink: 0;
}

.input-line {
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #000;
  width: 100%;
  padding: 5px;
  margin: 5px 0;
}

.line {
  position: relative;
  height: 30px;
  flex-grow: 1;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #000;
  padding: 5px 0;
  font-size: 16px;
}

.input-line:focus {
  outline: none;
  border-bottom: 2px solid #333;
  background-color: transparent;
}

.signature-section {
  margin-top: 50px;
}

.selected-signature {
  position: absolute;
  bottom: 10px;
  left: 0;
  white-space: nowrap;
  font-size: 14px;
}

.selected-date {
  position: absolute;
  top: 20px;  /* Adjusted for positioning */
  left: 55%;
  font-size: 14px;
  white-space: nowrap;
}

.signature-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.signature-popup {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  width: 400px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.signature-preview {
  margin-top: 20px;
}

.signature-display {
  font-size: 30px;
  color: #333;
  margin: 15px 0;
  cursor: pointer;
  text-align: center;
  padding: 10px;
  transition: background-color 0.2s ease;
}

.signature-display:hover {
  background-color: #f0f0f0;
  border-radius: 5px;
}

input[type='text'] {
  border: none !important;
  background-color: transparent !important;
  border-bottom: 2px solid black !important;
  color: black;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 0px;
  font-size: 16px;
}

input[type="text"]:focus {
  background-color: transparent !important;
  border-bottom: 2px solid black !important;
  box-shadow: none !important;
}

input[type="text"]::placeholder {
  color: gray;
}

.checkbox-container {
  display: flex;
  align-items: center;  /* Align checkbox and label on the same line */
  margin-bottom: 10px;   /* Space between each checkbox line */
}

.checkbox-container input[type="checkbox"] {
  width: 10%;  /* Take up 10% of the space */
  margin-right: 10px;  /* Space between checkbox and the text */
}

.checkbox-container label {
  flex: 1;  /* Let the label take up the remaining space */
  font-size: 16px;  /* Adjust text size */
}
.subsection {
  margin-left: 20px;  /* Adjust this to control the level of indentation */
}

/* Style the header section */
.contract-header {
  display: flex;
  justify-content: center;  /* Space out the logo and company name */
  align-items: center;  /* Vertically align the logo and company name */
  margin-bottom: 20px;  /* Space between the header and the contract content */
  padding-bottom: 10px;  /* Space below the header */
  border-bottom: 2px solid #001E2D;  /* Underline beneath the header */
  
}

/* Style for the logo image */
.company-logo {
  height: 50px;  /* Adjust the size of the logo */
  margin-right: 15px;  /* Space between the logo and the company name */
}

/* Style for the company name */
.company-name h1 {
  font-family: 'Times New Roman', Times, serif;
  font-size: 24px;
  margin: 0;  /* Remove default margin */
  color: #001E2D;
}
