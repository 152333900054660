/* Card container layout */
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; 
  gap: 20px;
  padding: 20px;
}

/* Card styling */
.card {
  width: 300px; /* Card width */
  height: 450px; /* Increased height to accommodate tagline and tags */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  background-color: #fff;
  transition: transform 0.3s ease;
}

/* Hover effect on card */
.card:hover .card-info {
  transform: translateY(-10px);
}

.card-link {
  text-decoration: none; /* Removes the underline from the link */
}

/* Image at the top of the card */
.card-image {
  width: 100%;
  height: 50%;
}

.card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Circle in the middle for profile picture */
.card-profile {
  position: absolute;
  top: 50%;
  left: 12%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50x;
  border-radius: 50%;
  border: 3px solid #001E2D; /* Border for profile picture */
  overflow: hidden;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}


.card-profile svg {
  width: 100%;  
  height: 100%;
  object-fit: contain; 
  color: #001E2D;
  
  
}

.card-profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Bottom half of the card (text section) */
.card-info {
  padding: 10px;
  text-align: center;
  background-color: #fff;
}

/* Company Name (Bold) */
.card-info h3 {
  margin: 10px 0;
  font-size: 12px;
  font-weight: bold;
  color:#666
 
}

/* Tagline (Bold) */
.card-info .tagline {
  font-size: 16px;
  font-weight: bold; /* Tagline in bold */
  color: #333;
  margin-bottom: 10px;
}

/* Short description text */
.card-info p {
  font-size: 14px;
  color: #666;
  margin: 5px 0;
}

/* Tags styling (Gmail-like tag style) */
.card-info .tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  margin-top: 10px;
}

.card-info .tag {
  background-color: #e0e0e0;
  color: #333;
  padding: 5px 12px;
  border-radius: 6px;
  font-size: 12px;
  display: inline-block;
}

/* Dynamic colors for different tags */
.card-info .tag:nth-child(1) {
  background-color: #3b3174;
  color: white;
}

.card-info .tag:nth-child(2) {
  background-color: #0f416a; /* Blue */
  color: white;
}

.card-info .tag:nth-child(3) {
  background-color: #0a625c; 
  color: white;
}

.card-info .tag:nth-child(4) {
  background-color: #145571; 
  color: white;
}

.card-info .tag:nth-child(5) {
  background-color: #45134e; 
  color: white;
}

/* Financials section styling */
.financials {
  text-align: left;  /* Left-align text */
  font-weight: bold; /* Make the text bold */
  margin-top: 10px;  /* Add some space above the financials section */
}

.financials p {
  font-size: 11px;
  margin: 5px 0;
  color: #adabab
}
