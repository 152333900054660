.item {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  border: 2px solid lightgray;
  border-radius: 8px;
  width: 100%;    
  max-width: 100%;
  max-width: 100%;       
  font-size: 20px;       
}

.title {
  display: flex;
  justify-content: left;
  align-items: center;
  font-weight: bold;
  font-size: 1.1rem;
  border-bottom: 2px solid gray;
  padding-bottom: 5px;
}

.container {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 1em;
  grid-template-areas:
      'title title'
      'main aside'
      'bottom bottom';
  height: fit-content; 
}

.item-3 {
  justify-content: center; /* Center the pagination vertically */
  align-items: start;
  width: 100%;
  max-width: 99%;
}

.item-4 {
  position: relative;
  display: flex; 
  flex-direction: column; 
  align-items:flex-start;
  padding: 10px; 
  border: 2px solid lightgray; 
  border-radius: 8px;
  width: 100%;
  max-width: 95%;
  max-width: 1fr;
  height: fit-content;
  margin-top: 0%;
}


.gridContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.housesList {
  flex: 1;
  padding: 1rem;
}
