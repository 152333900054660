@import "~font-awesome/css/font-awesome.min.css";

/* Styles for the html element (root of the document) */
html {
  height: 100%; /* Ensures that the html element takes full height */
}


/* Styles for the body element (content container) */
body {
  margin: 0; /* Reset default margin */
  padding: 0; /* Reset default padding */
  height: 100%; /* Body should fill the viewport */
  position: relative; /* Enable absolute positioning for child elements */
  overflow-x: hidden; /* Prevent horizontal scroll */
  overflow-y: hidden; /* Prevent vertical scroll (or change this to 'auto') */
}

.App {
  display: grid;
  grid-template-rows: auto, 1fr auto; /* header, main content, footer */
  min-height: 100vh;
  position: relative;
}

/* Each section will automatically fit its grid area */
.Header {
  grid-row: 1;
  width: 100%;
}

.App-main {
  grid-row: 2;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  /* Sticky Footer Setting */
  min-height: 100vh;


}

.Footer {
  grid-row: 3;
  width: 100%;
}