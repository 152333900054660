/* General styles for the pagination container */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 2px 0;
}

.page-item {
  margin: 0 5px;
}

.page-link {
  display: inline-block;
  padding: 8px 16px;
  background-color: #f4f4f4;
  border: 1px solid #ccc;
  color: #333;
  text-decoration: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Hover effect on page links */
.page-link:hover {
  background-color: #e4e4e4;
}

/* Active page item style */
.page-item.active .page-link {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

/* Disabled links (Previous / Next buttons) */
.page-item.disabled .page-link {
  background-color: #ddd;
  color: #aaa;
  cursor: not-allowed;
}

/* Style for the Previous and Next buttons */
.page-item .page-link {
  font-weight: bold;
}

/* Optional: Add a "..." to indicate skipped pages (for example, if we have more than 5 pages) */
.page-item.disabled .page-link {
  pointer-events: none;
}
.page-item.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.page-item.active .page-link {
  background-color: #007bff;
  color: white;
}

.pagination-container {
  font-size: 14px;
  margin-top: 5px;
  display: flex;
  justify-content: center;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 2px;
}

.page-item {
  margin: 0;
}

.page-link {
  font-size: 14px;
  padding: 5px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  color: #007bff;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.page-item.active .page-link {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
}

.page-item.disabled .page-link {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.page-link:hover {
  background-color: #e9ecef;
  border-color: #dee2e6;
  color: #0056b3;
}

.page-item.active .page-link:hover {
  background-color: #0056b3;
  border-color: #0056b3;
  color: white;
}

.loading {
  text-align: center;
  padding: 2rem;
  font-size: 1.2rem;
  color: #666;
}

.error-message {
  color: #dc3545;
  text-align: center;
  padding: 1rem;
  margin: 1rem 0;
  border: 1px solid #dc3545;
  border-radius: 4px;
}

.no-houses {
  text-align: center;
  padding: 2rem;
  color: #666;
}

.house-grid {
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  padding: 2px;
}

.house-card {
  width: 100%;
  padding: 5px;
  border-radius: 8px;  
  box-sizing: border-box;  
  background-color: white; 
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);  
  margin-bottom: 2px;
  font-size: 14px;
  line-height: 50%;
}

/* HouseViews.css */

/* Style for each county section */
.county-section {
  background-color: white;       /* White background for the county section */
  border: 1px solid #d3d3d3;     /* Light grey border */
  border-radius: 8px;            /* Rounded corners */
  margin-bottom: 10px;           /* Add some space between county sections */
  padding: 4px;            /* Padding inside the section */
  justify-items: center ;
}

/* Style for county header */
.county-section h4 {
  margin-top: 0;                 /* Remove margin from top */
  color: #333; 
  text-transform: capitalize;
}

.counties-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);  /* Creates 2 equal columns */
  gap: 20px;  /* Adds space between grid items */
  width: 100%;
}


/* Style for the statistics box */
.statistics-box-total {
  padding: 2px;
  margin-bottom: 25px;
  display: flex; /* Enable flexbox */
  flex-direction: column; /* Align content in a column (vertically) */
  align-items: center; /* Center horizontally */
  text-align: center; /* Center the text */
  gap: 20px;
}


/* Style for the h4 inside the statistics box */
.statistics-box-total h4 {
  margin: 0; /* Remove default margins */
  display: flex; /* Make it a flex container */
  flex-direction: row; /* Arrange the content in a row */
  align-items: center; /* Align the icons and text vertically */
  gap: 15px; /* Adds space between the elements inside the h4 */
}


/* Optional: Space out the icons and values */
.statistics-box {
  display: flex;
  padding: 2px;
  margin-bottom: 5px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: 	#6082B6;
  gap: 10px;
}

.market-opportunity-block {
  background-color: white;
  border:lightgray 1px solid;
  justify-content: center;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  color: 	#6082B6;
  align-items: center;
  gap: 15px; 

}

.market-opportunity-block h3 {
  text-align: center;
  color: #001e2d;
  }

  .county-box {
    background-color: white;
    border: 1px solid #d3d3d3;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 0px !important;
    align-items: center; 
    text-transform: capitalize;
    justify-items: center;
    font-weight: bold;
    font-size: 20px;
   }