

/* Apply background to only login-related pages */
.login-page {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
}

.login-page::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../../assets/Background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  filter: blur(8px);
  transform: scale(1.1);
  z-index: 1;
  /* opacity: 1; */
}

.login-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  width: 100%;
}

.login-form {
  background-color: #001e2d;
  padding: 30px;
  border-radius: 8px;
  width: 400px;
  z-index: 10;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #30638e;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: center;
}

label {
  flex: 1;
  margin-right: 10px;
  font-size: 16px;
  margin-bottom: 5px;
  color: #30638e;
  display: none;
}

.input-wrapper {
  position: relative;
  width: 100%;
}

.input-wrapper i {
  position: absolute;
  top: 38%;
  left: 10px;
  transform: translateY(-50%);
  color: #30638e;
  font-size: 18px;
  align-items: center;
  line-height: 1;
  z-index: 1;
}

input {
  width: 100%;
  padding: 12px 12px 12px 40px;
  border: 1px solid #93badb !important;
  border-radius: 4px;
  font-size: 16px;
  background-color: #93badb !important;
  color: #30638e;
  box-sizing: border-box;
  outline: none;
  margin-bottom: 15px;
  line-height: normal;
  vertical-align: middle;
}

input::placeholder {
  color: #30638e;
  opacity: 1;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #93badb inset !important;
  -webkit-text-fill-color: white !important;
  transition: background-color 5000s ease-in-out 0s;
}

input:focus {
  border-color: #93badb !important;
  background-color: #93badb !important;
  box-shadow: none;
}

button {
  width: 100%;
  padding: 12px;
  background-color: #30638e;
  color: white;
  border: 2px solid #30638e;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 15px;
}

button:hover {
  background-color: white;
  color: #30638e;
  border: 2px solid #30638e;
}

.error-message {
  color: red;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}
.forgot-password-container {
  margin-top: 15px;
  text-align: center;
  color: #001e2d; /* Color for the container */
}

/* Remove any underline, keep it simple like text */
.forgot-password-link {
  color: white; /* Text color */
  background-color: #001e2d;
  border-color: #001e2d;;
  text-decoration: none; /* No underline */
  cursor: pointer; /* Pointer cursor for clickable */
  display: inline; /* Make it inline, not block-level */
  text-align: center;
  margin-top: 20px; /* Space it below other elements */
}

.forgot-password-link:hover {
  color: lightblue; /* Text color */
  background-color: #001e2d;
  border-color: #001e2d;;
  text-decoration: none; /* No underline */
  cursor: pointer; /* Pointer cursor for clickable */
  display: inline; /* Make it inline, not block-level */
  text-align: center;
  margin-top: 20px; /* Space it below other elements */
}
/* Style for success messages */
.success-message {
  color: white; /* Text color */
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}

.nav-link {
  color: white;
  background-color: #001e2d;
  border-color: #001e2d;
  text-decoration: none;
}

.nav-link:hover {
  color: white;
  background-color: #001e2d;
  border-color: #001e2d;
  text-decoration: none;
}

/* Style for mobile responsiveness */
@media (max-width: 600px) {
  .login-form {
    width: 90%; /* Ensure the form takes up 90% on small screens */
  }
}

