.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  background: transparent;
}
.pagination-container button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #001e2d; /* Text color */
  background: none; /* No background */
  border: 1px solid #B0B0B0; /* Gray border */
  height: 25px;
  padding: 0 10px; /* Adjust padding for better spacing */
  cursor: pointer;
  text-decoration: none; /* Remove underline from the text */
  font-size: 12px;
  transition: color 0.2s, background-color 0.2s;
}


.pagination-container button.active {
  color: white; /* Active button color */
  background-color: #001e2d; /* Background for active button */
}

.pagination-container button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-container button:hover:not(.active) {
  background-color: #f1f1f1; /* Subtle hover effect */
  
}
