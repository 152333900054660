/* Subscribe.css */

/* Calendar Container */
.calendar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; /* Space between the text and the calendar */
  margin-bottom: 5px; /* Space between the headline and the next section */
  font-size: 0.9rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #001E2D; /* Dark color for text */
  position: relative; /* Ensure that the calendar can be positioned relative to this container */
  transform: translateY(10px);
}

/* Push the calendar out of the container */
.calendar-container .single-day-calendar {
  position: relative;
}
.subscribe-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns content to the left */
  padding: 20px;
  background-color: white; 
  border: 2px solid rgba(0, 30, 45, 0.1);
  border-top: 2px solid #282131;
  border-bottom: 3px solid #282131;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: fit-content; /* Shrinks to the width of its content */
  max-width: 100%;
}

.subscribe-heading {
  font-size: 0.8rem;
  margin-bottom: 1rem;
  color: #333;
  text-align: left; /* Keeps heading left-aligned */
}

.toggle-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 5px;
}
