.custom-quantity-input {
    font-size: 14px;
    padding: 8px;
    border: 1px solid #2c2b2b !important;
    border-radius: 4px;
    width: 120px;
    background-color: white !important; /* Add !important here */
  }
  
  .checkout-button {
    width: 120px;
    display: block;
    margin-left: auto;
    margin-right: 5px;
    margin-top: 20px;
  }