.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #001E2D;
  color: white;
  z-index: 1000;
}

.footer p {
  margin: 0;
  font-size: 14px;
}