.stepper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 250px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    height: 100%;
    padding-top: 0;
  }
  
  /* Styling for each step */
  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 65px;
    position: relative;
    opacity: 0.6;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  /* Step Icon Styling */
  .step-icon {
    position: relative;
    width: 30px;
    height: 30px;
    border: 2px solid #ccc;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    background-color: #fff;
    color: #ccc;
    transition: all 0.3s ease-in-out;
    margin-top: 10px;
  }
  
  /* Step Label Styling */
  .step-label {
    margin-top: 10px;
    font-size: 14px;
    color: #333;
  }
  
  /* Line connecting the steps */
  .step::after {
    content: "";
    position: absolute;
    left: 50%;
    top: calc(100% + 10px);
    width: 2px;
    height: 50px;
    background-color: #ccc;
    transform: translateX(-50%);
    z-index: -1;
  }
  
  /* Remove the line after the last step */
  .step:last-child::after {
    display: none;
  }
  
  /* For the orange step color */
  .step.orange .step-icon {
    border-color: orange;
    background-color: orange;
    color: white;
  }
  
  /* Comic bubble styling */
  .bubble {
    position: absolute;
    background-color: #fff;
    border: 2px solid #333;
    border-radius: 10px;
    padding: 10px;
    width: 120px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  }
  
  .bubble-arrow {
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #333;
  }
  
  .bubble-message {
    font-size: 14px;
    color: #333;
  }
  