
  
  /* This will make the entire page act as a flex container */
  .item {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    border: 2px solid lightgray;
    border-radius: 8px;
    width: 100%;
    font-size: 20px;
  }
  
  .title {
    display: flex;
    justify-content: left;
    align-items: center;
    font-weight: bold;
    font-size: 1.1rem;
    border-bottom: 2px solid gray;
    padding-bottom: 5px;
  }
  
  .container {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 1em;
    margin-bottom: 20px;
    grid-template-areas:
        'title title'
        'main aside'
        'bottom bottom';
  
  }
  
  .item-3 {
    grid-area: main;
    display: flex;
    gap: 20px;
    flex-direction: row;
    justify-content: center; 
    /* align-items: center; */
    width: 100%;
  }
/* 
  .item-4{
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: center; 
    gap: 10px; 
    border: 2px solid lightgray; 
    border-radius: 8px;
    width: 100%;
    font-size: 20px;
  }
   */
  
.gridContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    
  }
  