.Sidebar {
    width: 150px; /* Keep the width of the sidebar fixed */
    height: 100%; /* Ensure it fills the entire height of the grid container */

    position: absolute; /* Use absolute positioning inside .item-3 */
    top: 0; /* Align it to the top of the grid item */
    left: 0; /* Align it to the left */
    z-index: 1;
    overflow-x: hidden; /* Hide overflow horizontally */
    overflow-y: auto; /* Allow vertical scrolling if content exceeds height */
    padding-top: 20px; /* Adjust padding to your needs */
  }
  
  .row {
    display: flex; /* Make the row a flex container */
    align-items: center; /* Vertically align the items (icon and text) */
    padding: 10px 15px; /* Add some padding around each row item */
    cursor: pointer; /* Change cursor to pointer on hover */
    font-weight: bold; /* Make font bold */
    font-size: 14px; /* Make the font smaller */
  }
  
  .row #icon {
    margin-right: 10px; /* Add some space between the icon and the text */
    font-size: 18px; /* Adjust the icon size */
  }
  
  .row#active {
    background-color: white; /* Keep background white */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle drop shadow */
    border-top-right-radius: 20px; /* Round the top right corner */
    border-bottom-right-radius: 20px;
  }
  
  
  .row:hover {
    background-color: #f0f0f0; /* Change background color on hover */
    border-top-right-radius: 20px; /* Round the top right corner */
    border-bottom-right-radius: 20px;
  }
  