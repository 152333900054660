.about-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 1.5rem;
    background-color: #fff;
    border-top: 2px solid #ddd;
    border-bottom: 2px solid #ddd;
    width: 100%;
    font-family: 'Arial', sans-serif;
    text-align: center;
    box-sizing: border-box;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-top: 20px;
  }
  
  .about-heading {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    color: #333;
    font-weight: bold;
  }
  
  .about-text {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
    max-width: 600px;
  }
  