/* Header styles */
.header {
    width: 100%;
    display: flex;
    justify-content: space-between; /* This ensures space between logo and nav items */
    align-items: center; /* Vertically align items in the header */
    padding: 15px 50px;
    background-color: #001E2D;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    color: white;
    font-family: Arial, sans-serif;
    height: 60px;
  }
  .logo-container {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .logo {
    height: 30px;
    width: auto;
  }
  
  .company-name {
    font-size: 24px;
    font-weight: bold;
    color: white;
    margin-left: 10px;
    text-decoration: none !important; /* No underline on the text itself */
  }
  
  /* Apply the text-decoration none to the <a> tag itself */
  .logo-link {
    text-decoration: none !important; /* Ensure that the link has no underline */
  }
  
  
  /* Navigation links container */
  .nav-links {
    display: flex;
    gap: 20px; /* Space between links */
    margin-left: auto; /* Pushes nav links to the right */
    align-items: center; /* Vertically center align nav links */
  }
  
  /* Common button styles for all nav items */
  .nav-link {
    padding: 8px 16px; /* Button padding */
    background-color: transparent; /* Transparent background */
    color: white; /* Text color */
    border: 1px solid transparent; /* Border around the text */
    border-radius: 4px; /* Slightly rounded corners */
    cursor: pointer; /* Pointer cursor to indicate it's clickable */
    text-decoration: none; /* Remove underline */
    font-size: 14px; /* Adjust font size */
    display: inline-flex; /* Ensures button-like behavior */
    align-items: center; /* Vertically align the content */
    justify-content: center; /* Horizontally align the content */
    transition: all 0.3s ease;
    white-space: nowrap; /* Prevent text from wrapping */
    height: 100%; /* Ensure button takes full height of the nav item */
  }
  
  /* Hover effect for nav items */
  .nav-link:hover {
    background-color: white;
    color: #001e2d; /* Dark blue text on hover */
    border: 1px solid #001e2d; /* Dark blue border on hover */
  }
  
  /* Style for sub-links (dropdown items) */
  .sub-links a {
    display: block;
    padding: 8px 16px;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .sub-links a:hover {
    background-color: #30638e; /* Highlight on hover */
  }
  
  /* Dropdown icon styles */
  .nav-item .dropdown-icon {
    margin-left: 3px;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid white;
    display: inline-block;
    transition: transform 0.3s ease;
  }
  
  .nav-item .dropdown-icon.rotated {
    transform: rotate(180deg);
  }
  
  /* Ensure the dropdown items are aligned properly */
  .sub-links {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: rgba(0, 30, 45, 0.9);
    padding: 10px;
    border-radius: 5px;
    width: 150px;
    z-index: 100000;
  }
  
  /* When the section is toggled, show the dropdown */
  .nav-item .sub-links {
    display: block;
  }
  
  /* Remove default link styling and apply custom color for password link */
  .forgot-password-container a {
    color: #93badb; /* Light blue color */
    text-decoration: none !important; /* Remove underline */
    cursor: pointer; /* Make the cursor a pointer */
  }
  
  /* Ensure nav items are positioned relatively */
.nav-item {
    position: relative; /* This allows the dropdown to be positioned relative to this container */
  }
  
  /* Style for sub-links (dropdown items) */
  .sub-links {
    display: none; /* Hide by default */
    position: absolute; /* Position relative to the parent .nav-item */
    top: 100%; /* Position the dropdown directly below the nav button */
    left: 0; /* Align to the left edge of the button */
    background-color: rgba(0, 30, 45, 0.9); /* Background color */
    padding: 10px;
    border-radius: 5px;
    width: auto; /* Ensure width fits content */
    min-width: 150px; /* Ensure it doesn't collapse too much */
    z-index: 3; /* Make sure dropdown is above other elements */
  }
  
  /* When the section is toggled, show the dropdown */
  .nav-item .sub-links {
    display: block; /* Show dropdown when active */
  }
  
  /* Adjust hover behavior for sub-links */
  .sub-links a {
    display: block; /* Each link takes up the full width */
    padding: 8px 16px;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .sub-links a:hover {
    background-color: #30638e; /* Highlight on hover */
  }
  