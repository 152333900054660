/* Radio.module.css */
.radioGroup {
  display: flex;
  flex-direction: column; /* Stacks radio options vertically */
  gap: 5px; /* Adds space between each radio option */
  align-items: flex-start; /* Aligns all options to the left */
}

.radioItemWrapper {
  display: flex;
  align-items: center; /* Aligns both radio button and label vertically in the center */
  justify-content: flex-start; /* Ensures they are aligned to the left */
  white-space: nowrap; /* Prevents wrapping */
  margin: 0; /* Ensures no additional margin */
  padding: 0; /* Ensures no padding */
}

.radioOption {
  display: flex;
  font-size: 14px; /* Sets font size for the label */
  text-align: left; /* Ensures left alignment */
  width: 100%; /* Ensures the entire space is used for alignment */
  cursor: pointer;
}

.radioOption input[type="radio"] {
  margin-right: 8px; /* Adds spacing between the radio button and the label */
  /* Custom radio button size */
  width: 16px; /* Width of the radio button */
  height: 16px; /* Height of the radio button */
  border: 2px solid #333; /* Border color for radio button */
  background-color: white; /* White background */
  display: inline-block; /* Ensures the button behaves like an inline element */
  margin: 10px;
}

/* Optional: remove default styling from p and label elements */
.radioItemWrapper p,
.radioOption label {
  margin: 0;
  padding: 0;
 
}
